export function drawHeadingVectors() {
  const headings = document.querySelectorAll(".prose h1, .prose h2, .prose h3")

  headings.forEach((heading) => {
    const span = document.createElement("span")
    span.classList.add("heading-gradient")
    span.textContent = heading.textContent
    heading.innerHTML = span.outerHTML
  })
}
